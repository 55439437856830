import { MantineProvider } from "@mantine/core";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
            colorScheme: "light",
            colors: {
                // Add your color
                deepBlue: ["#E9EDFC", "#C1CCF6", "#99ABF0"],
                // or replace default theme color
                blue: ["#E9EDFC", "#C1CCF6", "#99ABF0"],
            },

            shadows: {
                md: "1px 1px 3px rgba(0, 0, 0, .25)",
                xl: "5px 5px 3px rgba(0, 0, 0, .25)",
            },

            breakpoints: {
                xs: "30em",
                sm: "48em",
                md: "64em",
                lg: "74em",
                xl: "90em",
            },

            fontFamily: "Lato, sans-serif",
            fontSizes: {
                xs: "0.6rem",
                sm: "0.75rem",
                md: "0.9rem",
                lg: "1rem",
                xl: "1.2rem",
            },
        }}
    >
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <App />
        </BrowserRouter>
    </MantineProvider>
);
