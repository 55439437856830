import React from "react";

import { Container } from "@mantine/core";

import LandingPage from "./views/landing-page/LandingPage";

const App = () => {
    return (
        <Container
            fluid
            sx={{
                height: "100vh",
                width: "100vw",

                backgroundColor: "FFFFF",
            }}
        >
            <LandingPage />
        </Container>
    );
};

export default App;
