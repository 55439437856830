import { useEffect, useRef } from "react";

import { Center } from "@mantine/core";
import { FaLinkedin } from 'react-icons/fa';

import Typed from "typed.js";

const LandingPage = (props) => {
    const typingAnimationRef = useRef(null);

    useEffect(() => {
        const typed = new Typed(typingAnimationRef.current, {
            strings: [
                "Hi! I'm Xin",
                "你好! 我是王昕",
                "வரவேற்கிறோம்! \n என் பெயர் Xin",
                "Hallo! Ek is Xin",
            ],
            typeSpeed: 80,
            backSpeed: 80,
            loop: true,
        });

        return () => {
            // Destroy Typed instance during cleanup to stop animation
            // This line would never execute.

            // TODO: Change to only animate when within view
            typed.destroy();
        };
    }, []);

    return (
        <Center
            fluid
            sx={(theme) => ({
                height: "100%",
                width: "100%",

                margin: 0,
                flexDirection: "column",

                // Adjust font size based on screen
                "@media (max-width: 40em)": {
                    fontSize: `calc(2.5*${theme.fontSizes.lg})`,
                },
            })}
        >
            <Center
                sx={(theme) => ({
                    display: "inline",
                    textAlign: "center",

                    // Adjust font size based on screen
                    [`@media (max-width: ${theme.breakpoints.xs})`]: {
                        fontSize: `calc(2.5*${theme.fontSizes.lg})`,
                    },
                    [`@media (min-width: ${theme.breakpoints.xs}) and (max-width: ${theme.breakpoints.sm})`]:
                        {
                            fontSize: `calc(2.75*${theme.fontSizes.lg})`,
                        },
                    [`@media (min-width: ${theme.breakpoints.sm}) and (max-width: ${theme.breakpoints.md})`]:
                        {
                            fontSize: `calc(3*${theme.fontSizes.lg})`,
                        },
                    [`@media (min-width: ${theme.breakpoints.md}) and (max-width: ${theme.breakpoints.lg})`]:
                        {
                            fontSize: `calc(3.25*${theme.fontSizes.lg})`,
                        },
                    [`@media (min-width: ${theme.breakpoints.lg}) and (max-width: ${theme.breakpoints.xl})`]:
                        {
                            fontSize: `calc(4*${theme.fontSizes.lg})`,
                        },
                    [`@media (min-width: ${theme.breakpoints.xl})`]: {
                        fontSize: `calc(5*${theme.fontSizes.lg})`,
                    },
                })}
            >
                <span ref={typingAnimationRef} />
            </Center>
            <Center>
            <a href="https://www.linkedin.com/in/xin-wang-03975918a/" style={{ color: 'black', textDecoration: 'none' }}>
                <FaLinkedin size={32} />
            </a>
            </Center>
        </Center>
    );
};

export default LandingPage;
